const useInstagramAuth = () => {
  const onInstagramLogin = () => {
    const instagramAuthUrl = new URL(
      'https://www.facebook.com/v16.0/dialog/oauth'
    );

    instagramAuthUrl.searchParams.append('display', 'page');
    instagramAuthUrl.searchParams.append('response_type', 'code');
    instagramAuthUrl.searchParams.append(
      'client_id',
      process.env.REACT_APP_INSTA_CLIENT_ID
    );
    instagramAuthUrl.searchParams.append(
      'extras',
      JSON.stringify({
        setup: { channel: 'IG_API_ONBOARDING' },
      })
    );
    instagramAuthUrl.searchParams.append(
      'redirect_uri',
      process.env.REACT_APP_INSTA_REDIRECT_URL
    );
    instagramAuthUrl.searchParams.append(
      'scope',
      process.env.REACT_APP_INSTA_AUTH_SCOPE
    );
    window.location = instagramAuthUrl;
  };
  return { onInstagramLogin };
};

export default useInstagramAuth;
